import React from 'react';
import { BrowserRouter, HashRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useAppSelector } from '@app/hooks/reduxHooks';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import AttorneyProfileLayout from '@app/pages/Blinder/pages/profile/AttorneyProfileLayout';
import LawFirmProfileLayout from '@app/pages/Blinder/pages/profile/LawFirmProfileLayout';
import AdminProfileLayout from '@app/pages/Blinder/pages/profile/AdminProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';
import AcceptInvitePage from '@app/pages/AcceptInvitePage';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const UserPersonalInfoPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/UserPersonalInfoPage'));
const AttorneyPersonalInfoPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AttorneyPersonalInfoPage'));
const AttorneyLawfirmPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AttorneyLawfirmPage'));
const LawFirmInfoPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/LawFirmInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const UserPaymentPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/UserPaymentPage'));
const AttorneyPaymentPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AttorneyPaymentPage'));
const AdminPaymentPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AdminPaymentPage'));
const UserStatisticPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/UserStatisticPage'));
const AttorneyStatisticPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AttorneyStatisticPage'));
const AdminStatisticPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AdminStatisticPage'));
const MyCertificationPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/MyCertificationPage'));
const AttorneyCopyrightPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AttorneyCopyrightPage'));
const AdminCopyrightPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AdminCopyrightPage'));
const MyAttorneysPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/MyAttorneyPage'));
const AttorneyClientPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AttorneyClientPage'));
const LawFirmAttorneyPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/LawFirmAttorneyPage'));
const AdminUserListPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AdminUserListPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const MyMessagesPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/MyMessagesPage'));
const AdminMessagesPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AdminMessagesPage'));
const AttorneyMessagesPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AttorneyMessagesPage'));
const LawFirmMessagesPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/LawFirmMessagesPage'));
const AttorneyAuditTrailPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AttorneyAuditTrailPage'));
const AdminAuditTrailsPage = React.lazy(() => import('@app/pages/Blinder/pages/profile/AdminAuditTrailsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));
const BlinderMainPage = React.lazy(() => import('@app/pages/Blinder/MainPage'));
const SearchResultPage = React.lazy(() => import('@app/pages/Blinder/SearchResultPage'));
const CertificationDetailPage = React.lazy(() => import('@app/pages/Blinder/CertificationDetailPage'));
const TermsAndConditionsPage = React.lazy(() => import('@app/pages/Blinder/TermsAndConditionsPage'));
const BlinderFileCopyrightPage = React.lazy(() => import('@app/pages/Blinder/FileCopyrightPage'));
const OAuthCallbackPage = React.lazy(() => import('@app/pages/OAuthCallbackPage'));
const AuditTrailsPage = React.lazy(() => import('@app/pages/AuditTrails'));
const PricingPlanPage = React.lazy(() => import('@app/pages/PricingPlanPage'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const MedicalDashboard = withLoading(MedicalDashboardPage);
const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const UserPersonalInfo = withLoading(UserPersonalInfoPage);
const AttorneyPersonalInfo = withLoading(AttorneyPersonalInfoPage);
const AttorneyLawfirm = withLoading(AttorneyLawfirmPage);
const LawFirmInfo = withLoading(LawFirmInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);
const UserPayments = withLoading(UserPaymentPage);
const AttorneyPayments = withLoading(AttorneyPaymentPage);
const AdminPayments = withLoading(AdminPaymentPage);
const UserStatistics = withLoading(UserStatisticPage);
const AttorneyStatistics = withLoading(AttorneyStatisticPage);
const AdminStatistics = withLoading(AdminStatisticPage);
const MyCertifications = withLoading(MyCertificationPage);
const AuditTrails = withLoading(AuditTrailsPage);
const AttorneyCopyrights = withLoading(AttorneyCopyrightPage);
const AdminCopyrights = withLoading(AdminCopyrightPage);
const MyAttorneys = withLoading(MyAttorneysPage);
const AttorneyClients = withLoading(AttorneyClientPage);
const LawFirmAttorneys = withLoading(LawFirmAttorneyPage);
const AdminUserList = withLoading(AdminUserListPage);
const MyMessages = withLoading(MyMessagesPage);
const AdminMessages = withLoading(AdminMessagesPage);
const AttorneyMessages = withLoading(AttorneyMessagesPage);
const LawFirmMessages = withLoading(LawFirmMessagesPage);
const AttorneyAuditTrails = withLoading(AttorneyAuditTrailPage);
const AdminAuditTrails = withLoading(AdminAuditTrailsPage);
const PricingPlan = withLoading(PricingPlanPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

const BlinderMain = withLoading(BlinderMainPage);
const BlinderFileCopyright = withLoading(BlinderFileCopyrightPage);
const SearchResult = withLoading(SearchResultPage);
const CertificationDetail = withLoading(CertificationDetailPage);
const TermsAndConditions = withLoading(TermsAndConditionsPage);
const OAuthCallback = withLoading(OAuthCallbackPage);


export const AppRouter: React.FC = () => {
  const token = useAppSelector((state) => state.auth.token);
  const user = useAppSelector((state) => state.user.user);
  // console.log('AppRouter - read token', token);
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  // <BrowserRouter basename={'copyright'}>
  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route path="/" element={user !== null ? <Navigate to="/home" /> : <Navigate to="/auth/login" />} />
          <Route path="home" element={
            user === null ? <Navigate to="/certs" /> : 
            (user.role === 'admin' ? <AdminMessages />:
              (user.role === 'attorney' ? <AuditTrails />: (
                user.role === 'law_firm' ? <LawFirmAttorneys /> : 
                <MyCertifications />
              )))} />
          <Route path="protect" element={<BlinderFileCopyright />} />
          <Route path="certs" element={<SearchResult />} />
          <Route path="cert" element={<CertificationDetail />} />
          <Route path="terms_conditions" element={<TermsAndConditions />} />
          {/*<Route index element={<NftDashboard />} />*/}
          <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} />
          <Route path="apps">
            <Route path="feed" element={<NewsFeed />} />
          </Route>
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>
          <Route path="data-tables" element={<DataTables />} />
          <Route path="charts" element={<Charts />} />
          <Route path="maps">
            <Route path="google-maps" element={<Google />} />
            <Route path="leaflet-maps" element={<Leaflet />} />
            <Route path="react-simple-maps" element={<ReactSimple />} />
            <Route path="pigeon-maps" element={<Pigeons />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="pricing-plan" element={<PricingPlan />} />
          <Route path="n" element={<ProfileLayout />}>
            <Route path="profile" element={<UserPersonalInfo />} />
            <Route path="attorneys" element={<MyAttorneys />} />
            <Route path="certifications" element={<MyCertifications />} />
            <Route path="payments" element={<UserPayments />} />
            <Route path="messages" element={<MyMessages />} />
            {/* <Route path="audit-trails" element={<AuditTrails />} /> */}

            {/* <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<UserPayments />} />
            <Route path="payments2" element={<Payments />} />
            <Route path="statistic" element={<UserStatistics />} /> */}
          </Route>
          <Route path="att" element={user?.profile?.user?.pricing_plan ? <AttorneyProfileLayout /> : <Navigate to="/pricing-plan" />}>
            <Route path="profile" element={<UserPersonalInfo />} />
            <Route path="attorney_profile" element={<AttorneyPersonalInfo />} />
            <Route path="audit_trails" element={<AuditTrails />} />
            <Route path="law_firm" element={<AttorneyLawfirm />} />
            <Route path="clients" element={<AttorneyClients />} />
            <Route path="certifications" element={<MyCertifications />} />
            <Route path="payments" element={<UserPayments />} />
            <Route path="messages" element={<MyMessages />} />
            
            {/* <Route path="statistic" element={<AttorneyCopyrights />} />
            <Route path="statistic" element={<AttorneyStatistics />} /> */}
          </Route>
          <Route path="lf" element={user?.profile?.user?.pricing_plan ? <LawFirmProfileLayout /> : <Navigate to="/pricing-plan" />}>
            <Route path="profile" element={<UserPersonalInfo />} />
            <Route path="law-firm-info" element={<LawFirmInfo />} />
            <Route path="attorneys" element={<LawFirmAttorneys />} />
            <Route path="audit_trails" element={<AuditTrails />} />
            <Route path="certifications" element={<MyCertifications />} />
            <Route path="payments" element={<UserPayments />} />
            <Route path="messages" element={<MyMessages />} />
            {/* <Route path="statistic" element={<AttorneyStatistics />} /> */}
            {/* <Route path="attorney-detail" element={<AttorneyCopyrights />} />
            <Route path="attorney-activity" element={<AttorneyCopyrights />} />*/}
          </Route>
          <Route path="admin" element={<AdminProfileLayout />}>
            <Route path="messages" element={<AdminMessages />} />
            {/* <Route path="users" element={<AdminUserList />} />
            <Route path="payments" element={<AdminPayments />} />
            <Route path="certifications" element={<AdminCopyrights />} />
            <Route path="statistic" element={<AdminStatistics />} />
            <Route path="audit-trails" element={<AdminAuditTrails/>} />
            <Route path="certifications" element={<MyCertifications/>} /> */}
          </Route>
          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
          <Route path="callback" element={<OAuthCallback />} />
          <Route path="accept-invite" element={<AcceptInvitePage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};
